import React from 'react';
import './AstrologAIText.css';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../context/UserContext'; // Убедитесь, что путь к файлу правильный

const AstrologAIText = () => {
    const navigate = useNavigate();
    const { user } = useUser();

    const handleClick = () => {
        if (user.isAuthenticated) {
            navigate('/prediction');
        } else {
            navigate('/');
        }
    };

    return (
        <a className="astrologyai-container" onClick={handleClick}>
            <div className="astrologyai-text">Astrology AI</div>
        </a>
    );
};

export default AstrologAIText;
