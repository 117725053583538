export const IS_TEST_MODE = false;

//export const BASE_URL = 'http://127.0.0.1:49965';
export const BASE_URL = 'https://astrology-ai.site'

export const API_LOGIN_ENDPOINT = '/api/login/';
export const API_VERIFY_USER_TOKEN_ENDPOINT = '/api/verifyUserToken/';
export const API_USER_INFO_ENDPOINT = '/api/user_data/';
export const API_REGISTER_ENDPOINT = '/api/register/';
export const API_VERIFY_ENDPOINT = '/api/verifyUser/';
export const API_PASSWORD_RESET_ENDPOINT = '/api/resetPassword/';
export const API_ARTICLE_ENDPOINT = '/api/content/';
export const API_PROMO_ENDPOINT = '/api/promoCode/';
export const API_TRANZILA_HANDSHAKE_ENDPOINT = '/api/tranzila-handshake/';
export const API_GOOGLE_AUTH_ENDPOINT = '/api/google-auth/';








