import React, { useEffect, useState } from 'react';
import './Prediction.css';

import PredictionList from './PredictionList'; // Assuming this component is imported
import CalendarButtons from './CalendarButtons'; // Assuming this component is imported
import useButtonSelection from '../../hooks/useButtonSelection';

import appConfig from '../../static/json/appConfig.json';
import { useUser } from '../../context/UserContext';
import { useArticles } from '../../database/articlesManager';
import '../../styles/inputStyles.css'; // Правильный импорт общего CSS файла

const PredictionContent = ({ selectedZodiac }) => {
    console.log('PredictionContent is rendered');
    const { nextArticles } = useArticles();
    const { user } = useUser();
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(false);
    const buttonsTop = appConfig[user.language]["buttonsTop"];
    const [selectedTop, handleSelectionChangeTop] = useButtonSelection(buttonsTop);

    useEffect(() => {
        loadArticles();
    }, [selectedTop, selectedZodiac, user]);

    //const loadArticles = async () => {
    //    setLoading(true);  // устанавливаем loading в true перед загрузкой
    //    try {
    //        const recipient = selectedZodiac ? selectedZodiac : user.name;
    //        const articleType = selectedTop ? selectedTop.charAt(0).toUpperCase() + selectedTop.slice(1) : 'Day';
    //        console.log('PredictionContent Load articles:', recipient, articleType);
    //        const newArticles = await nextArticles(user, recipient, articleType, 0);
    //        console.log('Load articles:', newArticles.length);
    //        setArticles(newArticles);
    //    } finally {
    //        setLoading(false);  // устанавливаем loading в false после загрузки
    //    }
    //};

    //const onLoadMore = async () => {
    //    setLoading(true);  // устанавливаем loading в true перед загрузкой
    //    try {
    //        const recipient = selectedZodiac ? selectedZodiac : user.name;
    //        const articleType = selectedTop ? selectedTop.charAt(0).toUpperCase() + selectedTop.slice(1) : 'Day';
    //        const newArticles = await nextArticles(user, recipient, articleType);
    //        setArticles(newArticles);
    //    } finally {
    //        setLoading(false);  // устанавливаем loading в false после загрузки
    //    }
    //};

    const loadArticles = async (isInitial = true) => {
        if (loading) return; // предотвращаем параллельные запросы

        setLoading(true);
        try {
            const recipient = selectedZodiac ? selectedZodiac : user.name;
            const articleType = selectedTop ? selectedTop.charAt(0).toUpperCase() + selectedTop.slice(1) : 'Day';
            const newArticles = await nextArticles(user, recipient, articleType, isInitial ? 0 : undefined);

            // Если это начальная загрузка и статей мало или нет, делаем дополнительный запрос
            if (isInitial && (!newArticles || newArticles.length < 5)) { // предполагаем, что нужно минимум 5 статей
                const additionalArticles = await nextArticles(user, recipient, articleType);
                setArticles(additionalArticles);
            } else {
                setArticles(newArticles);
            }
        } finally {
            setLoading(false);
        }
    };

    // Теперь onLoadMore просто вызывает loadArticles с флагом
    const onLoadMore = () => loadArticles(false);


    return (
        <div >
            <CalendarButtons
                buttons={buttonsTop}
                selected={selectedTop}
                onSelectionChange={handleSelectionChangeTop}
            />

            <div>
                {loading && (
                    <div className="loading-container">
                        <img src="/logo.webp" className="spinning-logo" alt="Loading..." />
                    </div>
                )}
                <div style={{ opacity: loading ? 1 : 1 }}>
                    <div className="prediction-content-container prediction-content-flex1">
                        <PredictionList
                            articles={articles}
                            onLoadMore={onLoadMore}
                            selectedZodiac={selectedZodiac}
                            selectedTop={selectedTop}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PredictionContent;
