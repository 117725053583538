// Verification.js
import React, { useState } from "react";
import Container from "../common/Container";
import VerificationForm from "./VerificationForm";
import { useRegistration } from "../../hooks/useRegistration";
import { useUser } from "../../context/UserContext";
import { useVerification } from "../../hooks/useVerification";
import appConfig from "../../static/json/appConfig.json";
import "../../styles/inputStyles.css";
import "../../styles/designConstants.css";

import "./Verification.css";
import useBackHandler from "../../hooks/useBackHandler";
import { useNavigate } from "react-router-dom";

const Verification = () => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const { verifyUser, loading: verificationLoading } = useVerification();
  const { registerUser, loading: registrationLoading } = useRegistration();
  const [authError, setAuthError] = useState(null);
  const [initialValues, setInitialValues] = useState({
    square0: "",
    square1: "",
    square2: "",
    square3: "",
  });

  const commonText = appConfig[user.language]["common"];

  const onSubmitForm = async (values) => {
    let verification =
      values.square0 + values.square1 + values.square2 + values.square3;

    const response = await verifyUser({
      name: user.name,
      email: user.email,
      password: user.password,
      verification: verification,
      language: user.language,
    });

    if (response.success) {
      navigate("/profile");
    } else {
      setAuthError(response.error);
      setInitialValues({
        square0: "",
        square1: "",
        square2: "",
        square3: "",
      });
    }
  };

  const onResend = async () => {
    //let name = user.name
    //let email = user.email
    //let password = user.password
    //const response = await registerUser({ name, email, password });

    //if (response.success) {

    //} else {
    //    setAuthError(response.error);
    //    setInitialValues({
    //        square0: '',
    //        square1: '',
    //        square2: '',
    //        square3: '',
    //    });
    //}

    navigate("/profile");
  };

  return (
    <div className="overlay parent-centered-div verification-container">
      <div className="centered-div">
        <div className="verification-title-container">
          <h1 className="titleText verification-title">
            {commonText["Verification"]}
          </h1>
        </div>

        <div className="verification-title">
          <div className="text verification-enterCode">
            {
              commonText[
                "Enter the code we just send you on your email address"
              ]
            }
          </div>
        </div>

        {authError && <p className="errorText textAlignCenter">{authError}</p>}

        <VerificationForm
          onSubmit={onSubmitForm}
          onResend={onResend}
          initialValues={initialValues}
        />

        {(verificationLoading || registrationLoading) && (
          <div className="center">
            <div className="loader"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Verification;
