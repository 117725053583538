import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import "./Menu.css";

import ChangePasswordForm from "../ChangePassword/ChangePassword";
import Profile from "../profile/Profile";
import ContentSelector from "../ContentSelector/ContentSelector";
import ContactSettings from "../ContactSettings/ContactSettings";
import Subscription from "../subscription/Subscription";
import { useUser } from "../../context/UserContext";
import logout from "../../services/logout";
import useBackHandler from "../../hooks/useBackHandler";

const Menu = ({ onBack, isInsideMenu, setIsInsideMenu }) => {
    const navigate = useNavigate(); 
    const [selectedMenu, setSelectedMenu] = useState(null);
    const { user, setUser, resetUser } = useUser();
    const menuNames = require("../../static/json/appConfig.json")[user.language]["menuNames"];

    //useEffect(() => {
    //  if (!isInsideMenu) {
    //    setSelectedMenu(null);
    //    setIsInsideMenu(true);
    //  }
    //});

    //const onSubmitProfile = () => {
    //  setSelectedMenu("Profile");
    //};

    //const onSubmitChangePassword = () => {
    //  setSelectedMenu("Change password");
    //};

    //const onSubmitContactSettings = () => {
    //  setSelectedMenu("Contact Settings");
    //};

    //const onSubmitContent = () => {
    //  setSelectedMenu("Content");
    //};

    //const onSubmitSubscription = () => {
    //  setSelectedMenu("Subscription");
    //};

    const onSubmitLogout = async () => {
        console.log("Log out button clicked");
        setSelectedMenu("Logout");
        await logout();
        resetUser();
        navigate("/");
    };

    const handleMenuClick = (route) => {
        navigate(`/prediction/menu/${route}`);
    };


    //const resetMenu = () => {
    //  if (selectedMenu != null) {
    //    setSelectedMenu(null);
    //  } else {
    //    onBack();
    //  }
    //};

    //useBackHandler(resetMenu);

    return (
        <div className="menu-container">
            <Routes>
                <Route
                    index
                    element={
                        <>
                            <button className="menu-button" onClick={() => handleMenuClick("profile")}>
                                <span className="menu-text">{menuNames["Profile"]}</span>
                            </button>
                            <button className="menu-button" onClick={() => handleMenuClick("content")}>
                                <span className="menu-text">{menuNames["Content"]}</span>
                            </button>
                            <button className="menu-button" onClick={() => handleMenuClick("contact-settings")}>
                                <span className="menu-text">{menuNames["Contact Settings"]}</span>
                            </button>
                            <button className="menu-button" onClick={() => handleMenuClick("subscription")}>
                                <span className="menu-text">{menuNames["Subscription"]}</span>
                            </button>
                            <button className="menu-button" onClick={() => handleMenuClick("change-password")}>
                                <span className="menu-text">{menuNames["Change password"]}</span>
                            </button>
                            <button className="menu-button" onClick={onSubmitLogout}>
                                <span className="menu-text">{menuNames["Log out"]}</span>
                            </button>
                        </>
                    }
                />
                <Route
                    path="profile"
                    element={<Profile isMenuEntry={true} />}
                />
                <Route
                    path="content"
                    element={<ContentSelector onBack={() => navigate("/prediction/menu")} />}
                />
                <Route
                    path="contact-settings"
                    element={<ContactSettings onBack={() => navigate("/prediction/menu")} />}
                />
                <Route
                    path="subscription"
                    element={<Subscription onBack={() => navigate("/prediction/menu")} header="false" />}
                />
                <Route
                    path="change-password"
                    element={<ChangePasswordForm onBack={() => navigate("/prediction/menu")} />}
                />
            </Routes>

            {/*{selectedMenu === null && (*/}
            {/*    <>*/}
            {/*        <button className="menu-button" onClick={onSubmitProfile}>*/}
            {/*            <span className="menu-text">{menuNames["Profile"]}</span>*/}
            {/*        </button>*/}
            {/*        <button className="menu-button" onClick={onSubmitContent}>*/}
            {/*            <span className="menu-text">{menuNames["Content"]}</span>*/}
            {/*        </button>*/}
            {/*        <button className="menu-button" onClick={onSubmitContactSettings}>*/}
            {/*            <span className="menu-text">{menuNames["Contact Settings"]}</span>*/}
            {/*        </button>*/}
            {/*        <button className="menu-button" onClick={onSubmitSubscription}>*/}
            {/*            <span className="menu-text">{menuNames["Subscription"]}</span>*/}
            {/*        </button>*/}
            {/*        <button className="menu-button" onClick={onSubmitChangePassword}>*/}
            {/*            <span className="menu-text">{menuNames["Change password"]}</span>*/}
            {/*        </button>*/}
            {/*        <button className="menu-button" onClick={onSubmitLogout}>*/}
            {/*            <span className="menu-text">{menuNames["Log out"]}</span>*/}
            {/*        </button>*/}
            {/*    </>*/}
            {/*)}*/}
            {/*{selectedMenu === "Contact Settings" && (*/}
            {/*    <ContactSettings onSubmit={resetMenu} onBack={resetMenu} />*/}
            {/*)}*/}
            {/*{selectedMenu === "Change password" && (*/}
            {/*    <ChangePasswordForm onSubmit={resetMenu} onBack={resetMenu} />*/}
            {/*)}*/}
            {/*{selectedMenu === "Profile" && (*/}
            {/*    <Profile onSubmit={resetMenu} onBack={resetMenu} />*/}
            {/*)}*/}
            {/*{selectedMenu === "Content" && (*/}
            {/*    <ContentSelector onSubmit={resetMenu} onBack={resetMenu} />*/}
            {/*)}*/}
            {/*{selectedMenu === "Subscription" && (*/}
            {/*    <Subscription onSubmit={resetMenu} onBack={resetMenu} header="false" />*/}
            {/*)}*/}
        </div>
    );
};

export default Menu;
