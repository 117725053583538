import React, { useRef, useEffect, useState } from "react";
import "./GreetingForm.css";
import "../../styles/inputStyles.css";
import SubmitButton from "../common/SubmitButton";
import appConfig from "../../static/json/appConfig.json";
import { useUser } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";

const GreetingList = ({ articles, images }) => {
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const { user, setUser } = useUser();

  useEffect(() => {
    if (isInitialLoad && scrollRef.current) {
      scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [isInitialLoad, articles]);

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (scrollHeight - scrollTop === clientHeight) {
      // Проверяем, достигнут ли конец
    }
  };

  const commonText = appConfig[user.language]["common"];

  const renderItem = (item, index) => (
    <div key={index} className="greeting-form-list-itemContainer">
      <div className="greeting-form-list-header">
        <div className="greeting-form-list-titleContainer">
          <span className="titleText greeting-form-list-titleText">
            {item.headerText}
          </span>
        </div>
      </div>
      {item.image && (
        <img
          src={images[item.name]}
          className={`greeting-form-list-itemImage ${index % 2 === 1 ? "odd" : ""}`}
          alt="Greeting"
        />
      )}
      <span
        className="text prediction-list-descriptionText"
        dangerouslySetInnerHTML={{ __html: item.bodyText }}
      />
      <div className="greeting-form-buttons-container">
        <div className="greeting-form-submit-button-container">
          <SubmitButton
            text={commonText["Register Your Stars"]}
            onSubmit={() => navigate("/signup")}
          />
        </div>
        <div className="greeting-form-submit-button-container">
          <SubmitButton
            text={commonText["Go to your predictions"]}
            onSubmit={() => navigate("/signin")}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div
      className="prediction-list-container"
      ref={scrollRef}
      onScroll={handleScroll}
      style={{ overflowY: "auto", height: "100%" }}
    >
      {articles.map((item, index) => renderItem(item, index))}
    </div>
  );
};

export default GreetingList;
