import { useState, useCallback } from "react";
import authenticateTokenOnServer from "../services/authenticateTokenOnServer";
import useUserState from "./useUserState";

// Хук useTokenAuthentication предназначен для проверки валидности токена пользователя.
// Он инкапсулирует логику верификации токена и обновления данных пользователя после успешной проверки.
export const useTokenAuthentication = () => {
  // Состояние для отслеживания загрузки при проверке токена
  const [loading, setLoading] = useState(false);

  // Получаем функцию обновления данных пользователя
  const { updateUserData } = useUserState();

  // Функция checkToken асинхронно обращается к серверу для проверки токена.
  // Она обрабатывает ответ сервера и обновляет состояние пользователя при успешной проверке.
  const checkToken = useCallback(async () => {
    // Активация индикатора загрузки перед началом запроса.
    setLoading(true);

    try {
      // Отправка запроса на сервер для проверки токена
      const response = await authenticateTokenOnServer();

      if (response.status === 200 && !response.data.error) {
        // При успешной верификации токена обновляем данные пользователя и возвращаем успех.
        updateUserData(response.data.user);
        return { success: true, user: response.data.user };
      } else {
        // В случае ошибок сервера возвращаем объект с информацией об ошибке.
        return { success: false, error: response.data.error };
      }
    } finally {
      // Выключение индикатора загрузки независимо от результата запроса.
      setLoading(false);
    }
  }, [updateUserData]);

  // Возвращаем функцию проверки токена и состояние загрузки для использования в компонентах.
  return {
    checkToken,
    loading,
  };
};
