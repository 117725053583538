import React from "react";
import "./Footer.css";
import "../../styles/inputStyles.css";
import PromptWithActionLink from "../common/PromptWithActionLink";
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();
    return (
        <footer className="footer-container text">
            <div className="promptWithActionLink-container">
                <button
                    onClick={() => {
                        console.log("Contacts pressed");
                        navigate("/contact");
                    }}
                    className="footer-container-button"
                >
                    Contact us
                </button>
            </div>

            <div className="promptWithActionLink-container">
                <button
                    onClick={() => {
                        console.log("Terms of Use and Cancellation Policy pressed");
                        navigate("/terms");
                    }}
                    className="footer-container-button"
                >
                    Terms of Use and Cancellation Policy
                </button>
            </div>

            {/*<div className="promptWithActionLink-container">*/}
            {/*    <button*/}
            {/*        onClick={() => {*/}
            {/*            console.log("Demo payment");*/}
            {/*            navigate("/confirmation")*/}
            {/*        }}*/}
            {/*        className="footer-container-button">*/}
            {/*        Demo payment*/}
            {/*    </button>*/}
            {/*</div>*/}

        </footer>
    );
};

export default Footer;
