import React, { useState, useEffect } from "react";
import "./Confirmation.css";
import "../../styles/inputStyles.css";
import { useUser } from "../../context/UserContext";
import appConfig from "../../static/json/appConfig.json";
import CustomInput from "../common/CustomInput";
import SubmitButton from "../common/SubmitButton";
import { Formik, Form, Field } from "formik";
//import googlePayIcon from "../../static/image/6929234_google_logo_icon.webp";
import useUserState from "../../hooks/useUserState";
import usePromoCodeValidation from "../../hooks/usePromoCodeValidation";
import TranzilaPaymentForm from "./TranzilaPaymentForm";
import { useNavigate } from "react-router-dom";

const Confirmation = ({ amount, plan, period }) => {
  const navigation = useNavigate();
  const [promoCode, setPromoCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(amount);
  const [promoError, setPromoError] = useState("");

  const { user } = useUser();
  const { updateUserData } = useUserState();

  const commonText = appConfig[user.language]["common"];
  const subscriptionText = appConfig[user.language]["Subscribtion"][1];

  const { validatePromoCode, loading, promoCodeData, error } =
    usePromoCodeValidation(); // Используем хук для проверки промокода

  const handleApplyPromo = async (values) => {
    const promoCode = values.promoCode;

    // Логика применения промокода
    if (!promoCode) {
      //setPromoError(commonText["Promo code is required"]);
      return;
    }

    const response = await validatePromoCode(promoCode);

    if (response.success) {
      const {
        is_active,
        expires_at,
        discount_amount,
        discount_type,
        usage_type,
        max_uses,
      } = response.data;

      const isExpired = expires_at ? new Date(expires_at) < new Date() : false;
      if (!is_active || isExpired) {
        setPromoError(commonText["Promo code is invalid or expired"]);
        setDiscount(0);
      } else {
        // Применяем скидку, если промокод валиден
        const calculatedDiscount = applyDiscount(
          amount,
          discount_amount,
          discount_type,
          usage_type,
          max_uses,
          period,
        );
        setDiscount(calculatedDiscount);
        setPromoError("");
        updateUserData({ promoCode });
      }
    } else {
      // Обработка ошибки при неверном промокоде
      console.error("Promo code error:", response.error);
      setPromoError(response.error);
      setDiscount(0);
    }
  };

  const applyDiscount = (
    amount,
    discountAmount,
    discountType,
    usageType,
    maxUses,
    period,
  ) => {
    // Если промокод ограничен первыми n месяцами
    let subscription_cost = amount;
    if (usageType === "Limited" && maxUses !== null) {
      if (period === "monthly") {
        // Применяем скидку на первые n месяцев для месячной подписки
        subscription_cost = amount;
      } else if (period === "yearly") {
        // Применяем скидку на эквивалент первых n месяцев для годовой подписки
        const monthlyAmountFromYearly = amount / 12;
        subscription_cost = monthlyAmountFromYearly * Math.min(maxUses, 12);
      }
    }

    // Применение скидки в зависимости от типа скидки
    if (discountType === "Absolute") {
      return Math.min(discountAmount, subscription_cost); // абсолютная скидка
    } else if (discountType === "Relative") {
      return discountAmount * (subscription_cost / 100); // процентная скидка
    }
    return 0;
  };

  const handleCancel = () => {
    // Логика отмены заказа
    console.log("Order cancelled");

    navigation(-1);
  };

  useEffect(() => {
    // Вычисление общей суммы
    const calculatedTotal =
      parseFloat(amount) - parseFloat(discount) + parseFloat(tax);
    setTotal(calculatedTotal.toFixed(2));
  }, [amount, discount, tax]);

  return (
    <div className="overlay bottom-div parent-centered-div">
      <div className="centered-div">
        <div className="confirmation-container text ">
          <div className="confirmation-left-column ">
            <div className="titleText">
              {commonText["Subscription"]}: {subscriptionText.Title}{" "}
            </div>
            <div className="titleText">
              {" "}
              {amount}$ / {commonText[period]}
            </div>
            <div className="confirmation-price-container">
              <div className="confirmation-price-row">
                <div>{commonText["Initial payment"]}:</div>
                <div className="confirmation-price-value">
                  {parseFloat(amount).toFixed(2)}$
                </div>
              </div>
              <div className="confirmation-price-row">
                <div>{commonText["Discount"]}:</div>
                <div className="confirmation-price-value">
                  {parseFloat(discount).toFixed(2)}$
                </div>
              </div>
              <div className="confirmation-price-row">
                <div>{commonText["Tax"]}:</div>
                <div className="confirmation-price-value">
                  {parseFloat(tax).toFixed(2)}$
                </div>
              </div>
              <div className="confirmation-price-row">
                <div>{commonText["Total"]}:</div>
                <div className="confirmation-price-value">
                  {parseFloat(total).toFixed(2)}$
                </div>
              </div>
            </div>
          </div>
          <div className="confirmation-right-column">
            <div className="confirmation-right-container">
              {promoError && <div className="errorText">{promoError}</div>}
              <Formik
                initialValues={{ promoCode: "" }} //**** Инициализация promoCode
                onSubmit={handleApplyPromo} //**** Привязка handleApplyPromo к отправке формы
              >
                {({ values, handleChange }) => (
                  <Form>
                    <div className="promo-code">
                      <div className="titleText">
                        {commonText["Enter Promo Code"]}
                      </div>
                      <Field //**** Использование Field для promoCode
                        name="promoCode"
                        as={CustomInput}
                        label=""
                        placeholder={commonText["Enter Promo Code"]}
                        type="text"
                        setRef={() => {}}
                        removeFocusFromAll={() => {}}
                        nextFieldFocus={() => {}}
                      />
                      <SubmitButton
                        text="Apply"
                        onSubmit={() => {
                          console.log(values.promoCode); //**** Вывод значения promoCode на консоль
                          handleApplyPromo(values);
                        }}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        <div className="confirmation-container text ">
          <div className="confirmation-left-column ">
            <TranzilaPaymentForm
              total_sum={total}
              period={period}
              plan={plan}
              promoCode={promoCode}
            />
          </div>
          <div className="confirmation-right-column">
            <SubmitButton text="Cancel" onSubmit={handleCancel} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
