import React, { useState } from "react";
import "./Confirmation.css";
import "../../styles/inputStyles.css";
import SubmitButton from "../common/SubmitButton";
import appConfig from "../../static/json/appConfig.json";
import "../../styles/inputStyles.css";
import { useUser } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";

function OrderCompletedFailure() {
  const [error] = useState(
    new URLSearchParams(window.location.search).get("error") || "",
  );
  const { user } = useUser();
  const commonText = appConfig[user.language]["common"];
  const navigate = useNavigate();

  const handleConfirm = () => {
    console.log("OrderCompletedFailure: onConfirm has been called"); // Логирование для дебага
    navigate(-1);
  };

  return (
    <div className="overlay bottom-div parent-centered-div">
      <div className="centered-div">
        <div className="success-container text">
          <div className="success-icon-circle">
            <span
              role="img"
              aria-label="error"
              style={{ color: "#FF5252", fontSize: "60px" }}
            >
              ✗
            </span>
          </div>

          <h2 className="titleText">{commonText["Payment error!"]}</h2>
          <p className="success-message">
            {error ||
              commonText[
                "Unfortunately, there was an error processing your payment."
              ]}
          </p>

          <p className="success-message">{commonText["Please try again."]}</p>
          <SubmitButton text={commonText["Close"]} onSubmit={handleConfirm} />
        </div>
      </div>
    </div>
  );
}

export default OrderCompletedFailure;
