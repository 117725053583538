import React, { useState, useEffect, useCallback } from 'react';

import GreetingList from './GreetingList';
import '../../styles/inputStyles.css';
import './GreetingForm.css';

import { useUser } from '../../context/UserContext';
import appConfig from '../../static/json/appConfig.json';
import useSetLanguageForUser from '../../hooks/useSetLanguageForUser';
import { useNavigate } from 'react-router-dom';

const GreetingForm = () => {
    const { user, setUser } = useUser();
    const setLanguageForUser = useSetLanguageForUser();

    const navigate = useNavigate();
    const commonText = appConfig[user.language]['common'];
    const greetingPage = appConfig[user.language]['GreetingPage'];

    useEffect(() => {
        if (user.isAuthenticated) {
            navigate('/prediction');
        }
    }, []);

    const images = {
        Welcome: require('../../static/image/Sky1.webp'),
        Neuronet: require('../../static/image/Sky2.webp'),
        Zodiac: require('../../static/image/Sky3.webp'),
        Individual: require('../../static/image/Sky4.webp'),
        Availability: require('../../static/image/Sky5.webp'),
        Daily: require('../../static/image/Sky6.webp'),
        'Astro Rating': require('../../static/image/Sky7.webp'),
    };

    useEffect(() => {}, []);

    useEffect(() => {
        setLanguageForUser();
    }, [setLanguageForUser]);

    return <GreetingList articles={greetingPage} images={images} />;
};

export default GreetingForm;
