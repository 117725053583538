// Subscription.js
import React, { useState, useEffect } from "react";
import { useUser } from "../../context/UserContext";
import appConfig from "../../static/json/appConfig.json";
import SubscriptionPage from "./SubscriptionPage";
//import useBackHandler from "../../hooks/useBackHandler";
import PaymentProcess from "./PaymentProcess";

import "../../styles/inputStyles.css";
import "../../styles/colors.css";
import "./Subscription.css";

import { useSendUserInfo } from "../../hooks/useSendUserInfo";
import { useNavigate } from "react-router-dom";

const Subscription = ({ header }) => {
  const { user } = useUser();
  const { sendUserInfo, loading } = useSendUserInfo();
  const [authError, setAuthError] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null); // Для хранения данных подписки

  const subscriptionText = appConfig[user.language]["Subscribtion"];
  //const commonText = appConfig[user.language]["common"];
  const navigation = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const index = subscriptionText.findIndex(
      (subscription) => subscription.Title === user.subscription_type,
    );
    if (index !== -1) setCurrentPage(index);
  }, [user.subscription_type, subscriptionText]);

  const previousImage = () => {
    setCurrentPage(
      (prevPage) =>
        (prevPage - 1 + subscriptionText.length) % subscriptionText.length,
    );
    console.log(`Subscription prevPage ${currentPage}`);
  };

  const nextImage = () => {
    let nextPage = (currentPage + 1) % subscriptionText.length;
    console.log(`Subscription nextPage ${nextPage}`);
    setCurrentPage(nextPage);
  };

  const renderItem = ({ item, index }) => {
    return (
      <SubscriptionPage
        data={item}
        onSubmit={(values) => onSubmitForm(values)}
        onPrevious={previousImage}
        onNext={nextImage}
        isFirstPage={index === 0}
        isLastPage={index === subscriptionText.length - 1}
      />
    );
  };

  const onSubmitForm = async (values) => {
    const isDifferent =
      values.subscription_type !== user.subscription_type ||
      values.subscriptionPerMonth !== user.subscriptionPerMonth ||
      values.subscriptionPerYear !== user.subscriptionPerYear;

    if (isDifferent) {
      if (values.subscription_type === "FREE") {
        const response = await sendUserInfo(
          {
            subscriptionPerMonth: values.subscriptionPerMonth,
            subscriptionPerYear: values.subscriptionPerYear,
            subscription_type: values.subscription_type,
          },
          "subscription",
        );

        if (!response.success) {
          setAuthError(response.error);
        } else {
          navigation("/prediction");
        }
      } else {
        setSubscriptionData(values);
      }
    }
  };

  const handleBackFromPayment = () => {
    setSubscriptionData(null); // Возвращаемся к выбору подписки
  };

  // Новое добавление под Google Pay
  //const handleSubscription = (plan) => {
  //    const amount = plan === 'monthly' ? 10 : 100; // example amounts
  //    return <GooglePayComponent amount={amount} plan={plan} />;
  //};

  //const onViewableItemsChanged = useCallback(({ viewableItems }) => {
  //  if (viewableItems.length > 0) {
  //    const firstVisibleItem = viewableItems[0];
  //    setCurrentPage(firstVisibleItem.index);
  //  }
  //}, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <span>Loading...</span>
      </div>
    );
  }

  if (subscriptionData) {
    const amount =
      subscriptionData.subscriptionPerMonth ||
      subscriptionData.subscriptionPerYear;
    const plan = subscriptionData.subscription_type;
    const period = subscriptionData.subscriptionPerMonth ? "monthly" : "yearly";

    return (
      <PaymentProcess
        amount={amount}
        plan={plan}
        period={period}
        onBack={handleBackFromPayment}
      />
    );
  }

  return (
    <div className={`subscription-upper-container`}>
      <div
        className={`subscription-container  ${header === "true" ? "subscription-margin-top" : ""}`}
        style={{
          display: "flex",
          width: `${100}%`,
          transform: `translateX(-${currentPage * 100}vw)`,
          transition: "transform 0.5s ease-in-out",
        }}
      >
        {subscriptionText.map((item, index) => (
          <div key={"page_" + index} className="subscription-container-page">
            {renderItem({ item, index })}
          </div>
        ))}
      </div>

      {authError && (
        <div className="errorText subscription-error-message">{authError}</div>
      )}

      <div className="paginationDots">
        {subscriptionText.map((_, i) => (
          <div
            key={i}
            className={`subscription-dot ${i === currentPage ? "activeDot" : "inactiveDot"}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Subscription;
