import React from 'react';
import { UserProvider } from './context/UserContext';
import MainPage from './components/AppRouter/AppRouter';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import OrderCompletedSuccess from './components/subscription/OrderCompletedSuccess';
import OrderCompletedFailure from './components/subscription/OrderCompletedFailure';
import useOverlayHeightAdjustment from './hooks/useOverlayHeightAdjustment';
import AppRouter from './components/AppRouter/AppRouter';

function App() {
    useOverlayHeightAdjustment();

    return (
        <UserProvider>
            <Router>
                <AppRouter />
            </Router>
        </UserProvider>
    );
}

export default App;
