// ProfileForm.js
import React, { useState, useEffect } from 'react';
import '../../styles/inputStyles.css';
import '../../styles/colors.css';

import appConfig from '../../static/json/appConfig.json';

import { profileValidationSchema } from './validationSchema';
import useFocusManagement from '../../hooks/useFocusManagement';
import { useScreenOffsetControl } from '../../hooks/useScreenOffsetControl';
import { useCountryAndCity } from './useCountryAndCity';
import { getComponentByName, getAdditionalPropsByName } from './getComponent';

import CustomForm from '../common/CustomForm';
import { componentInstaller } from '../../utils/componentInstaller';
import { useUser } from '../../context/UserContext';
import { useSendUserInfo } from '../../hooks/useSendUserInfo';
import { useNavigate } from 'react-router-dom';

// Основной компонент формы профиля
const ProfileForm = ({ onSubmit, isMenuEntry }) => {
    console.log('ProfileForm render');
    const navigate = useNavigate();
    const { user, setUser } = useUser();
    const { sendUserInfo, loading } = useSendUserInfo();
    const [authError, setAuthError] = useState(null);

    const commonText = appConfig[user.language]['common'];

    const onSubmitForm = async (profileData) => {
        const response = await sendUserInfo(
            {
                gender: profileData.gender,
                birth_date: profileData.birth_date,
                birth_time: profileData.birth_time,
                birth_country: profileData.birth_country,
                birth_city: profileData.birth_city,
                biography: profileData.biography,
            },
            'profile',
        );

        if (!response.success) {
            setAuthError(response.error);
        } else {
            if (isMenuEntry) {
                navigate('/prediction/personal');
            } else {
                navigate('/language-form?redirect-url=/subscription');
            }
        }
    };

    const submitText = user.registrated ? commonText['Select'] : commonText['Continue'];

    // Загрузка метаданных полей из JSON
    const fieldMetadataArray = appConfig[user.language]['profileMetadataArray'];

    // Создание массива идентификаторов ссылок
    const refIdentifiers = fieldMetadataArray.map((item) => item.name);

    // Создание объекта смещений для каждого поля
    const screenOffsets = Object.fromEntries(
        fieldMetadataArray.map((item) => [item.name, item.screenOffset]),
    );

    // Хук для управления смещением экрана
    const [screenOffset, setFieldOffset, panResponder, updateContentHeight] =
        useScreenOffsetControl();

    // Хуки для управления фокусом
    const { setRef, removeFocusFromAll, nextFieldFocus } =
        useFocusManagement(refIdentifiers);

    // Хуки для выбора страны и города
    /*console.log("ProfileForm - user.birth_city", user.birth_city);*/

    const [countryList, cityList, onSelectCountry, onSelectCity] = useCountryAndCity();
    const handleSelectCountry = (country) => {
        // Сначала вызовите оригинальную функцию onSelectCountry
        onSelectCountry(country);
        setUser({
            ...user, // Скопировать все текущие свойства user
            birth_city: '', // Установить birth_city в пустую строку
        });
    };

    useEffect(() => {
        if (user.birth_country) {
            onSelectCountry(user.birth_country);
        }
    }, []);

    // Создание конфигурации полей
    const fieldsConfig = fieldMetadataArray.map((metadata) => ({
        ...metadata,
        //component: getComponentByName(metadata.name),
        component: componentInstaller(metadata.component),
        additionalProps: getAdditionalPropsByName(
            metadata.name,
            user,
            countryList,
            cityList,
            handleSelectCountry,
            onSelectCity,
        ),
    }));

    if (loading) {
        return (
            <div className="flex-center">
                <div className="spinner large"></div>
            </div>
        );
    }

    return (
        <div
            {...panResponder.panHandlers}
            className="profile-form  parent-centered-div"
            style={{ marginTop: `${screenOffset}px` }}
            onLayout={(event) => {
                const height = event.target.offsetHeight;
                updateContentHeight(height);
            }}
        >
            {authError && <div className="error-text">{authError}</div>}

            <CustomForm
                fieldsConfig={fieldsConfig}
                setRef={setRef}
                removeFocusFromAll={removeFocusFromAll}
                nextFieldFocus={nextFieldFocus}
                initialValues={{
                    gender: user.gender,
                    birth_date: user.birth_date,
                    birth_time: user.birth_time,
                    birth_country: user.birth_country,
                    birth_city: user.birth_city,
                    biography: user.biography,
                }}
                validationSchema={profileValidationSchema}
                onSubmit={onSubmitForm}
                submitText={submitText}
            />
        </div>
    );
};

export default ProfileForm;
