import React from 'react';
import './Zodiac.css'; // Include this for your CSS styles
import IconButton from './IconButton'; // Assuming this component is properly adapted for React.js
import useZodiacImages from '../../hooks/useZodiacImages';

const Zodiac = ({ onZodiacSelected }) => {
    const zodiacImages = useZodiacImages(); // Ensure useZodiacImages hook is adapted for web

    const handleIconButtonClick = (name) => {
        // console.log(name);
        onZodiacSelected(name);
    };

    return (
        <div style={{ flex: 1 }}>
            <div className="zodiac-container">
                <div className="zodiac-table">
                    {zodiacImages.map((zodiac, index) => (
                        <div key={index} className="zodiac-table-cell">
                            <IconButton
                                name={zodiac.name}
                                icon={zodiac.imageSrc}
                                onSubmit={handleIconButtonClick}
                            />
                            {/*<div>{zodiac.label}</div> */}{/* Отображение label */}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Zodiac;