import React from 'react';
import './LanguageSelector.css';
import { useNavigate } from 'react-router-dom'; // Импортируем CSS-файл с нужными стилями

// Импортируем изображение
const images = {
    Welcome: require('../../static/image/earth1.webp'),
};

const LanguageSelector = () => {
    const navigate = useNavigate();
    return (
        <div className="language-selector-container">
            <button
                onClick={() => navigate('/language-form')}
                style={{
                    backgroundImage: `url(${images['Welcome']})`,
                }}
                className="language-selector-button"
                title="Select language"
            >
                {/* Можно добавить текст или иконку, если нужно */}
            </button>
        </div>
    );
};

export default LanguageSelector;
