// Импорт необходимых модулей
import React, { useState } from 'react';
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import appConfig from '../../static/json/appConfig.json';

import useFocusManagement from '../../hooks/useFocusManagement';
import { useScreenOffsetControl } from '../../hooks/useScreenOffsetControl';
import useBackHandler from '../../hooks/useBackHandler';

import CustomForm from '../common/CustomForm';
import { componentInstaller } from '../../utils/componentInstaller';

import { useUser } from '../../context/UserContext';
import '../../styles/inputStyles.css';
import "./ContactSettings.css"

import { useSendUserInfo } from '../../hooks/useSendUserInfo';

// Основной компонент формы профиля
const ContactSettings = () => {
    const navigate = useNavigate();
    const { user, setUser } = useUser();
    const { sendUserInfo, loading } = useSendUserInfo();
    const [authError, setAuthError] = useState(null);

    // Загрузка метаданных полей из JSON
    const fieldMetadataArray = appConfig[user.language]["contactSettingsMetadataArray"];
    const commonText = appConfig[user.language]["common"];

    // Создание массива идентификаторов ссылок
    const refIdentifiers = fieldMetadataArray.map(item => item.name);

    // Хуки для управления фокусом
    const { setRef, removeFocusFromAll, nextFieldFocus } = useFocusManagement(refIdentifiers);

    // Создание конфигурации полей
    const fieldsConfig = fieldMetadataArray.map(metadata => ({
        ...metadata,
        component: componentInstaller(metadata.component),
    }));

    const onSubmitForm = async (values) => {
        //console.log('LanguageForm onSubmitForm values:', values);
        const response = await sendUserInfo({
            useEmail: values.useEmail
        }, 'notification_preferences');
        //console.log('LanguageForm onSubmitForm response:', response);

        if (!response.success) {
            setAuthError(response.error);
        }
        else {
            navigate('/prediction/personal');
        }
    };

    if (loading) {
        return (
            <div class="contact-settings-loading-container">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <div className="overlay parent-centered-div">
            <div className="centered-div">
                <h1 class="titleText">
                    {commonText["Select ways to receive predictions"]}
                </h1>

                <CustomForm
                    fieldsConfig={fieldsConfig}
                    setRef={setRef}
                    removeFocusFromAll={removeFocusFromAll}
                    nextFieldFocus={nextFieldFocus}
                    initialValues={{
                        useEmail: user.useEmail,
                    }}
                    onSubmit={onSubmitForm}
                    submitText={commonText["Select"]}
                />


                {authError && <div className="error-text">{authError}</div>}

                {user.subscription_type === "FREE" && (
                    <div>
                        <div className="text contact-settings-text-container">{commonText["ContactSettings1"]}</div>
                        <div className="text contact-settings-text-container">{commonText["ContactSettings2"]}</div>
                        <div className="text contact-settings-text-container">{commonText["ContactSettings3"]}</div>
                    </div>
                )}

            </div>
        </div>

    );
};

export default ContactSettings;