import React, { useState } from "react";
import { Routes, Route, useNavigate, useLocation, Navigate } from "react-router-dom";
import "./Prediction.css";

import PredictionContent from "./PredictionContent";
import CalendarButtons from "./CalendarButtons";
import Zodiac from "./Zodiac";
import Menu from "./Menu";

import { useUser } from "../../context/UserContext";

import useButtonSelection from "../../hooks/useButtonSelection";
import appConfig from "../../static/json/appConfig.json";


const Prediction = () => {
    /*  console.log("Prediction is rendered");*/
    const navigate = useNavigate();
    const location = useLocation();
    const { user, setUser } = useUser();
    const [isInsideMenu, setIsInsideMenu] = useState(false);
    const buttonsBottom = appConfig[user.language]["buttonsBottom"];


    const getActiveButton = (path) => {
        if (path.includes('/menu')) return 'Menu';
        if (path.includes('/zodiac')) return 'Zodiac';
        return 'Person';
    };

    const handleSelectionChangeBottom = (selection) => {
        switch (selection) {
            case 'Person':
                navigate('/prediction/personal');
                break;
            case 'Zodiac':
                navigate('/prediction/zodiac/select');
                break;
            case 'Menu':
                navigate('/prediction/menu');
                setIsInsideMenu(true);
                break;
        }
    };

    const handleZodiacSelection = (zodiacName) => {
        setUser((prevUser) => ({
            ...prevUser,
            zodiacSign: zodiacName,
        }));
        navigate(`/prediction/zodiac/${zodiacName.toLowerCase()}`);
    };

    //const [selectedBottom, handleSelectionChangeBottom] =
    //    useButtonSelection(buttonsBottom);
    //const [selectedZodiac, setSelectedZodiac] = useState(null);

    //const handleZodiacSelection = (zodiacName) => {
    //    setSelectedZodiac(zodiacName);
    //    handleSelectionChangeBottom("");
    //    setUser((prevUser) => ({
    //        ...prevUser,
    //        zodiacSign: zodiacName,
    //    }));
    //};



    //const onMenuBack = () => {
    //    setIsInsideMenu(false);
    //    handleSelectionChangeBottom("Person");
    //};

    return (
        <div className="prediction-overlay bottom-div parent-centered-div">
            <div className="centered-div ">
                <Routes>
                    <Route index element={<Navigate to="/prediction/personal" replace />} />
                    <Route path="personal" element={<PredictionContent />} />
                    <Route path="zodiac">
                        <Route path="select" element={<Zodiac onZodiacSelected={handleZodiacSelection} />} />
                        <Route path=":sign" element={<PredictionContent selectedZodiac={user.zodiacSign} />} />
                    </Route>
                    <Route
                        path="menu/*"
                        element={
                            <Menu
                                onBack={() => {
                                    setIsInsideMenu(false);
                                    navigate('/prediction/personal');
                                }}
                                isInsideMenu={isInsideMenu}
                                setIsInsideMenu={setIsInsideMenu}
                            />
                        }
                    />
                </Routes>






                {/*{selectedBottom === "Person" && <PredictionContent />}*/}
                {/*{selectedBottom === "" && (*/}
                {/*    <PredictionContent selectedZodiac={selectedZodiac} />*/}
                {/*)}*/}
                {/*{selectedBottom === "Zodiac" && (*/}
                {/*    <Zodiac onZodiacSelected={handleZodiacSelection} />*/}
                {/*)}*/}
                {/*{selectedBottom === "Menu" && (*/}
                {/*    <Menu*/}
                {/*        onBack={onMenuBack}*/}
                {/*        goToGreetingForm={() => navigation("/")}*/}
                {/*        isInsideMenu={isInsideMenu}*/}
                {/*        setIsInsideMenu={setIsInsideMenu}*/}
                {/*    />*/}
                {/*)}*/}

                <div className="prediction-bottom-button-container">
                    <CalendarButtons
                        buttons={buttonsBottom}
                        selected={getActiveButton(location.pathname)}
                        onSelectionChange={handleSelectionChangeBottom}
                        setIsInsideMenu={setIsInsideMenu}
                    />
                </div>
            </div>
        </div>
    );
};

export default Prediction;
