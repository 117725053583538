import React, { useState } from "react";
import "./Confirmation.css";
import "../../styles/inputStyles.css";
import SubmitButton from "../common/SubmitButton";
import appConfig from "../../static/json/appConfig.json";
import "../../styles/inputStyles.css";
import { useUser } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";

const OrderCompletedSuccess = () => {
    const [message] = useState(
        new URLSearchParams(window.location.search).get("message") || "",
    );
    const { user } = useUser();
    const commonText = appConfig[user.language]["common"];
    const navigate = useNavigate();
    return (
        <div className="overlay bottom-div parent-centered-div">
            <div className="centered-div">
                <div className="success-container text">
                    <div className="success-icon-circle">
                        <span
                            role="img"
                            aria-label="success"
                            style={{ color: "#4CAF50", fontSize: "60px" }}
                        >
                            ✓
                        </span>
                    </div>

                    <h2 className="titleText">
                        {commonText["Order completed successfully!"]}
                    </h2>
                    <p className="success-message">{message}</p>

                    <p className="success-message">
                        {commonText["Check your email for your receipt."]}
                    </p>
                    <SubmitButton
                        text={commonText["Close"]}
                        onSubmit={() => navigate('/prediction/personal')}
                    />
                </div>
            </div>
        </div>
    );
};

export default OrderCompletedSuccess;
