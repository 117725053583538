// Profile.js
import React from "react";
import "../../styles/inputStyles.css";
import "../../styles/colors.css";
import useBackHandler from "../../hooks/useBackHandler";

import ProfileForm from "./ProfileForm";

const Profile = ({ isMenuEntry }) => {
    return (
        <div className="overlay parent-centered-div">
            <div className="centered-div">
                <ProfileForm isMenuEntry={isMenuEntry} />
            </div>
        </div>
    );
};

export default Profile;
